import { normal } from '../../normal.js';
import { global } from '../../global.js';
import { FASTElement, css, html, customElement } from '@microsoft/fast-element';
import { menu } from '../data/index.js';

const styles = [
  global,
  normal,
  css`
    .navMenu {
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 75%;
     
      background-color: transparent;
    }
    .navMenu li {
  
      width: 100px;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .navMenu a {
      width: 100%;
      color: var(--deux);

    }

    /* logo (cl) */
    .logoLink {
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .logoImg {
      width: clamp(300px, 25vw, 500px);
    }
    @media (max-width: 480px) {
     
    }
  `,
];


const template = html<AppHeader>`
  <header>
    <nav>
      <a href="/" class="logoLink">
        <img class="logoImg" src="/assets/brand-kit/clx_KAI2.svg" />
      </a>
      
      <ul class="navMenu">
        ${menu.header.map(
          (item) =>
            ` <li> <a title="nav" href="${item.link}"> ${item.title} </a> </li> `
        ).join('')}
        
      </ul>

    </nav>
  </header>
`;

@customElement({ name: 'app-header', template, styles })
export class AppHeader extends FASTElement {}
