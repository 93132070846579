import { css } from "@microsoft/fast-element";

export const normal = css`
/* general styles */
main, header, nav, article,
select, option, pre, code,
blockquote, 
div, span, section,
legend, fieldset, 
menu, ol, ul, li,
h1, h2, h3, h4, h5, p, a,
textarea, input,
dialog, form, button,
textarea, input
{
    margin: 0;
	  padding: 0;
    font-family: inherit;

    background-color: transparent;
    border: none;
    outline: none;
}

 

/* viewports */
main {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
section {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 85%;
  min-height: 65vh;
  background: transparent;
  border: none;
  border-radius: 16px;
}

input[type="range"] {
  -webkit-appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}

ul, ol, menu {
  list-style: none;
  text-align: center;
}

/* viewports */
main {
  width: 100%;
}
section {
  width: 85%;
}

/* stacking */
  .plein {
    flex-direction: row;
  }
  .vert {
    flex-direction: column;
  }

/* images */
img[class="tiny"] {
  width: 12.5%;
}
img[class="petit"] {
  width: 25%;
}
img[class="med"] {
  width: 50%;
}
img[class="grande"] {
  width: 75%;
}
img[class="gros"] {
  width: 100%;
}

  /* titles */
  h1 {
    font-size: clamp(24px, 5vw, 36px);
    font-weight: 400;
  }
  /* sub-titles */
  h2, h3 {
    font-size: clamp(18px, 2.5vw, 24px);
    font-weight: 500;
  }
  /* text */
  h4, h5, h6, p {
    font-size: clamp(12px, 2.5vw, 16px);
    font-weight: 400;
  }
  h4 {
    font-weight: 600;
  }
  a {
    font-size: clamp(16px, 2.5vw, 18px);
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }
  a[title="nav"] {
    font-size: clamp(16px, 2.5vw, 18px);
  }
  button {
    font-size: clamp(12px, 1vw, 16px);
  cursor: pointer;
  width: 100%;
  }

  textarea, input {
  font-size: clamp(12px, 1vw, 16px);
  width: 100%;
  padding: 1%;
  margin: .5% 0;
}
textarea {
  resize: none;
}
textarea:focus, input:focus {
outline: none;
}
;`