import { normal } from '../../normal.js';
import { global } from '../../global.js';
import { FASTElement, html, css, customElement } from '@microsoft/fast-element';
import { landing } from '../data/index.js';
import '../components/body.js';

const styles = [
  normal,
  global,
  css`
    .servMenu {
      width: clamp(300px, 35vw, 500px);
      gap: 5%;
      margin: 2.5% 0;
      padding: 2.5%;
    }
    .servMenu li {
      flex-flow: column wrap;
      padding: 2.5%;
      width: 25%;
    }
    .servMenu img {
      padding: 2.5%;
    }
    div {
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 2.5%;
    }
    div span {
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      width: 25%;
    }
    h5 {
      text-align: center;
      font-weight: 500;
      width: clamp(325px, 5vw, 350px);
    }
    h1 {
      padding: 1% 0;
      text-align: center;
      width: clamp(400px, 5vw, 525px);
    }
    .icon {
      width: clamp(50px, 5vw, 75px);
    }
 

    @media (max-width: 768px) {
      .servMenu {
        margin: 5% 0;
      }
      h5 {
        width: 325px;
      }
    }
  `,
];

const template = html`
  <app-body>
    <section>
    
    <div>
    <h1>Sourcing made simple, shipping made swift!</h1>
    <h5>Kingsmith Atlantic offers a wide range of supply chain solutions, ensuring that your needs are met with expertise and excellence.
</h5>
</div>
  <fieldset class="servMenu">
    <legend><h2>Our Services</h2></legend>
    <div>
      ${landing.landing.map((item) => {
        return `
          <span>
              <img class="icon" src="${item.icon}" />
              <h2>${item.name}</h2>
          </span>
        `;
      })
      .join('')}
    </div>
  </fieldset>
  <h4>To learn more about how our services can meet your needs, please reach out to us directly for personalized assistance.</h4>
   


    </section>
  </app-body>
`;

@customElement({
  name: 'app-landing',
  template,
  styles,
})
export class AppLanding extends FASTElement {}
