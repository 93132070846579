import { normal } from '../../normal.js';
import { global } from '../../global.js';
import { menu } from '../data/index.js';
import { FASTElement, html, css, customElement } from '@microsoft/fast-element';

const template = html<AppFooter>`
  <footer>
    <article>
        <ul class="piedList">
        ${menu.footer.map((item) => {
          return `
            <li>
              <a target="_blank" title="nav" href="${item.link}"> ${item.title} </a>
            </li>
          `;
        })
        .join('')}
      </ul>
      <ul class="socialList">
        <li>
          <a class="med" target="_blank" href="https://trucksnparts.ca/">
          <img src="/assets/icons/ic_TRUX.svg" />
          </a>
        </li>
        <li>
          <a target="_blank" class="med" href="https://www.linkedin.com/company/kingsmith-atlantic">
          <img src="/assets/icons/icon-linkedin.svg" />
          </a>
        </li>
        <li>
        <a target="_blank" class="med" href="https://www.facebook.com/p/KingSmith-Atlantic-Inc-100062965193382/?paipv=0&eav=AfbqipFFB521s159fmIHsLuuhM3dydZ6bVGFryZF3z26uZ8_UUmGrbwWt0INfvRp3ko&_rdr">
          <img src="/assets/icons/icon-facebook.svg" />
          </a>
        </li>
      </ul>
    </article>
  </footer>
`;
const styles = [
  normal,
  global,
  css`
  /* footer */
 footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 0;
  width: 100%;
  padding: .5% 0;
  text-align: center;
}

/* footerList */
article {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  width: 90%;
  background: transparent;
  border: none;
} 

    .piedList {
      width: 45%;
      gap: 2.5%;
    }
    .piedList li a {
      width: 100%;
      font-size: clamp(10px, 1vw, 12px);
    }


    .socialList {
      justify-content: end;
      width: 35%;
      gap: 1%;
    }
    .socialList li {
      width: clamp(25px, 1vw, 35px);
    }
    .socialList a {
      width: 100%;
    }
    .socialList img {
   
    }

    @media (max-width: 768px) {
      .socialList {
        justify-content: end;
        width: 35%;
        gap: 2.5%;
      }
    }
  `,
];
@customElement({ name: 'app-footer', template, styles })
export class AppFooter extends FASTElement {
  // @query('footer') footerElement!: HTMLElement;
  footerElement!: HTMLElement;

  connectedCallback() {
    super.connectedCallback();
    this.footerElement = this.shadowRoot!.querySelector('footer')!;
    //show footer if page is small enough - window.scrollY + window.innerHeight > document.body.scrollHeight - 100 (100px from bottom of the page )
    if (
      window.scrollY + window.innerHeight >
      document.body.scrollHeight - 100
    ) {
      this.footerElement.classList.add('show');
    }
    window.addEventListener('scroll', this.scrollHandler);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('scroll', this.scrollHandler);
  }

  scrollHandler = () => {
    if (
      window.scrollY + window.innerHeight >
      document.body.scrollHeight - 100
    ) {
      this.footerElement.classList.add('show');
    } else if (
      this.footerElement.classList.contains('show') &&
      window.scrollY + window.innerHeight < document.body.scrollHeight - 150
    ) {
      this.footerElement.classList.remove('show');
    }
  };
}
