import { css } from "@microsoft/fast-element";

export const global = css`
/* dis[lay = Flex */
main, header, nav, article,
select, option, 
pre, code, blockquote, 
div, span, section,
legend, fieldset, 
menu, ol, ul, li,
h1, h2, h3, h4, h5, p, a,
textarea, input,
dialog, form, button,
textarea, input
{
  display: flex;
}

/* nav */
header {
  flex-flow: row wrap;
  justify-content: center;
  align-items: end;
  background: #fff;

  position: sticky;
  z-index: 100;
  top: 0;
  width: 100%;
}
  /* navList */
  nav {
    flex-flow: column wrap;
    justify-content: end;
    align-items: center;

    width: 85%;
    background: transparent;
    border-radius: 16px;
    border: none;
  }

/* containers */
legend, fieldset {
    border-radius: 8px;
    border: 2px solid var(--ponc);

  }
  legend {
    padding: 0 2.5%;
  }
  fieldset {
    border: 2px solid var(--ponc);
    margin: 2.5%
  }


/* lists */
menu, ol, ul {
  text-align: center;
}

  code {
    flex-flow: column wrap;
    background-color: var(--tert_1);
    padding: 5%;
  }

/* text */
h1, h2, h3, h4, h5, h6, p {
  color: var(--deux);
  margin: 0;
  padding: 0;
}
a { 
    cursor: pointer;
    color: var(--deux);
    transition: 0.1s ease-in-out;
    justify-content: center;
    align-items: center;
    text-align: center;
  }


/* interactions */
form {
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--deux);

  width: 65%;
}
textarea, input {
  color: var(--cler_1);
}
textarea {
  height: 10rem;
}
textarea:focus, input:focus {
}
input[type="range"] {
  -webkit-appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
}

  /* buttons */
  button {
  transition: 0.2s ease-in-out;
  border: none;
  background-color: transparent;
  }
  /* dialogs */
  /* forms */
  
;`